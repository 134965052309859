body, .app-container {
    background-color: #263238 !important;
}

.logo {
    width: 125px !important;
    height: auto !important;
}

.header {
    padding: 8px !important;
}

footer {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    background-color: #263238 !important;
    color: #FFFFFF !important;
}

.link {
    color: #FFFFFF !important;
    text-decoration: none !important;
}

.underline {
    text-decoration: underline !important;
}

.container {
    position: relative !important;
    padding-top: 128px !important;
    padding-bottom: 128px !important;
    background-color: white !important;
}

.m-8 {
    margin: 8px !important;
}

.m-16 {
    margin: 16px !important;
}

.m-32 {
    margin: 32px !important;
}

.m-64 {
    margin: 64px !important;
}

.m-128 {
    margin: 128px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-16 {
    margin-top: 16px !important;
}

.mt-32 {
    margin-top: 32px !important;
}

.mt-64 {
    margin-top: 64px !important;
}

.mt-128 {
    margin-top: 128px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mr-16 {
    margin-right: 16px !important;
}

.mr-32 {
    margin-right: 32px !important;
}

.mr-64 {
    margin-right: 64px !important;
}

.mr-128 {
    margin-right: 128px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mb-32 {
    margin-bottom: 32px !important;
}

.mb-64 {
    margin-bottom: 64px !important;
}

.mb-128 {
    margin-bottom: 128px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.ml-16 {
    margin-left: 16px !important;
}

.ml-32 {
    margin-left: 32px !important;
}

.ml-64 {
    margin-left: 64px !important;
}

.ml-128 {
    margin-left: 128px !important;
}

.ml-auto {
    margin-left: auto !important;
}

.desc {
    text-align: center !important;
}

.w-100 {
    width: 100% !important;
}

.blue-select {
    background-color: #455a6442 !important;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-top: 4px;
    border-radius: 3px;
}

/** Cookie Constent **/
.dark.cc_dialog {
    background-color: #263238 !important;
}

.dark.cc_dialog button.cc_b_ok {
    color: white !important;
    margin-right: 16px !important;
    background-color: #455A64 !important;
}

.cc_dialog button.cc_b_cp {
    margin-left: 0 !important;
    margin-right: 16px !important;
    margin-top: 8px !important;
}

.dark.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_footer .cc_cp_f_save button {
    color: white !important;
    background-color: #455A64 !important;
}

.cc_cp_head, .cc_cp_footer, .cc_cp_m_head, .cc_cp_m_footer {
    background-color: #263238 !important;
}

.dark.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_content .cc_cp_m_content {
    background-color: #455A64 !important;
}

.dark.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_content .cc_cp_m_menu li[active=true] { 
    background-color: #263238 !important;
}